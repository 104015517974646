<template>
  <div class="information">
      <h1>404</h1>
      <h3>Страница не найдена</h3>
  </div>
</template>
<script>

export default {

  data: () => ({
    
  }),
  computed: {
    
  },
  methods: {
  }
}
</script> 
